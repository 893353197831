<template>
  <div id="app">


    <div class="app-container">

      <!-- <div class="side-panel-container">

        
        <div class="navigation">

          <a class="brand-header" href="http://twitch.tv/alxios" target="blank">

            <i class="fab fa-twitch"></i>
            <div class="separator">|</div>
            <div class="channel-name">
              Alxios
              <div class="channel-link">
                www.twitch.tv/alxios
              </div>
            </div>

          </a>

          <router-link class="navigation-elem" to="/dashboard">
            <i class="fad fa-chart-pie-alt"></i>
            <div class="title">Dashboard</div>

            <div class="selected"></div>
          </router-link>

          <router-link class="navigation-elem" to="/shop">
            <i class="fad fa-shopping-cart"></i>
            <div class="title">Boutique</div>

            <div class="selected"></div>
          </router-link>

          <router-link class="navigation-elem" to="/requests">
            <i class="fad fa-gamepad-alt"></i>
            <div class="title">En attentes</div>

            <div class="notif-container">2 disp.</div>
          </router-link>

          <router-link class="navigation-elem" to="/achievement">
            <i class="fad fa-trophy"></i>
            <div class="title">Succes</div>
          </router-link>

          <router-link class="navigation-elem" to="/classement">
            <i class="fad fa-chart-bar"></i>
            <div class="title">Classement</div>
          </router-link>


          <router-link class="navigation-elem" to="/settings">
            <i class="fad fa-cogs"></i>
            <div class="title">Paramètres</div>
          </router-link>


        </div>

        
          <div class="bottom-block">

            
          <div class="succes-container">

            
            <div class="succes-content">

              <div class="title">Achievements</div>
              <div class="description">
                Je suis un texte peu utile, mais tout de meme assez long pour l'example.
              </div>

              <div class="progress-bar">
                <span class="bar"></span>
              </div>
            </div>

            <div class="succes-content absolute">

              <div class="title">Achievements</div>
              <div class="description">
                Je suis un texte peu utile, mais tout de meme assez long pour l'example.
              </div>

              <div class="progress-bar">
                <span class="bar"></span>
              </div>
            </div>

            <i class="fad fa-trophy"></i>

          </div>

          <div class="user-container"  v-if="user">

            <div class="profil-image" :style="{
            'background-image': `url(${user.profile_image_url})`
            }"></div>

            <div class="user-info" v-if="userData">
              <div>{{ user.display_name }}</div>
              <div>
              {{ new Intl.NumberFormat().format(Math.round(userData.points)) }} <span class="currency"><i class="fab fa-btc"></i>oules</span>
              </div>
            </div>

          </div>


          </div>



      </div> -->

      <transition name="fade" :key="$route.name" mode="out-in">
        <router-view class="page-container-container" />
      </transition>

      <!-- <div class="live-stream-container">
        <iframe
          src="https://player.twitch.tv/?channel=alxios&parent=localhost"
          height="100%"
          width="100%"
          frameborder="0"
          scrolling="false"
          allowfullscreen="true">
        </iframe>
      </div> -->

    </div>


    <!-- <div class="header" v-if="$route.name !== 'Home' && $route.name !== 'hype'">
      <div class="header-content">

        <div class="menu-container">

          <router-link class="neon" to="/shop">
            <div class="title">Christmas Special</div>
          </router-link>

          <router-link class="elem" to="/shop">Shop</router-link>
          <router-link class="elem" to="/requests">Mes demande</router-link>
          <router-link class="elem" to="/classement">Classement</router-link>

        </div>

        <div class="info-container" v-if="user">
          <div class="user-info" v-if="userData">
            <div>{{ user.display_name }}</div>
            <div>
            {{ new Intl.NumberFormat().format(userData.points) }} <span class="currency"><i class="fab fa-btc"></i>oules</span>
            </div>
          </div>

          <div class="profil-image" :style="{
          'background-image': `url(${user.profile_image_url})`
          }"></div>
        </div>
        
      </div>
    </div> -->

 

  </div>
</template>


<script>
import './firebase'
import { mapState } from 'vuex'

export default {
  name: 'App',

  // firestore() {
  //   return {
  //     users: db.collection('users')
  //   }
  // },

  data () {
    return {
      users: [],

      navigations: [
        {

        }
      ]
    }
  },

  computed: {
    ...mapState(['user']),
    userData() {
      return this.users.find(x => x.userId === this.user.id)
    }
  },

  async created() {
    // try {
    //   await this.$store.dispatch('fetchUser')
    // } catch (e) {
    //   if (this.$route.name === 'hype') return

    //   this.$router.push('/')
    // }
  },

}

</script>

<style>
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, captBBBEion, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

html, body {
	height: 100%;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
  border-collapse: collapse;
	border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
	box-sizing: border-box;
}

</style>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700');
@import url('https://fonts.googleapis.com/css2?family=Yusei+Magic&display=swap');

@font-face {
  font-family: 'Whitney';
  font-weight: 300;
  src: url('./assets/font/whitneylight.otf');
}

@font-face {
  font-family: 'Whitney';
  font-weight: 400;
  src: url('./assets/font/whitneymedium.otf');
}

@font-face {
  font-family: 'Whitney';
  font-weight: 600;
  src: url('./assets/font/whitneysemibold.otf');
}

@font-face {
  font-family: 'Whitney';
  font-weight: 700;
  src: url('./assets/font/whitneybold.otf');
}

.user-container {

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 32px 0;

  border-top: 2px solid #1e1e1e;

  margin-top: 42px;

  .profil-image {
    height: 48px;
    width: 48px;
    background-size: cover;
    background-position: center;
    border-radius: 100%;

    margin-right: 18px;
  }
}

body {
  font-family: 'Whitney';
  background: #1E1E1E;

  color: #B9BBBE;
}

$title-color: #FFFFFF;

</style>

<style lang="scss" scoped>

.notif-container {
  position: absolute;
  right: 0;
  background: #F45C3B;
  font-size: 14px;
  height: 24px;
  display: flex;
  padding: 0 13px;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
}

.live-stream-container {
  z-index: 1000;
  position: absolute;
  right: 24px;
  bottom: 24px;
  width: 100%;
  max-width: 480px;
  height: calc(480px/(16/9));
  border-radius: 12px;

  background: black;
  overflow: hidden;
  box-shadow:
    0 0 0 1px rgba(4,4,5,0.15),
    0 8px 16px rgba(0,0,0,0.24);

  transition: 350ms ease all;
}

.live-stream-container:hover {
  max-width: 880px;
  height: calc(880px/(16/9));
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.page-container-container {
  width: 100%;

  padding: 0 24px;
}

.brand-header {
  display: flex;
  flex-direction: row;

  align-items: center;

  font-size: 32px;

  color: white;

  padding: 32px 0;

  font-weight: 600;

  border-bottom: 2px solid #1e1e1e;

  cursor: pointer;

  &:hover {

    .separator {
      transform: translateX(124px) rotate(18deg);
    }

    .channel-name {
      transform: translateX(-18px);
    }

    .channel-link {
      color: #d2b3ffab !important;
    }

  }

  .channel-name {
    margin-left: 10px;
    margin-top: -3px;
    font-size: 26px;

    transition: .3s ease all;

    cursor: pointer;

    .channel-link {
      font-size: 11px;
      font-weight: 300;
      line-height: 15px;
      color: #747474;
      margin-left: 0px;
      font-family: 'Open Sans';

      transition: .3s ease all;
    }
  }

  .separator {
    margin-top: -8px;
    transform: rotate(18deg);
    color: #b9bbbe;

    transition: .3s ease all;
  }

  svg {
    color: #9147FF;
    margin-right: 16px;
  }
}

.succes-container {

  cursor: pointer;

  transition: .3s ease all;

  &:hover {
    transform: scale(1.03);
  }

  svg {
    position: absolute;
    top: 0;
    right: -37%;
    height: 100%;
    width: 100%;
    color: #9fd8bb;
    z-index: 0;
  }

  position: relative;
  overflow: hidden;

  background-color: #6aca99;

  border-radius: 10px;

  padding: 18px 24px;

  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

  .succes-content.absolute {
    position: absolute;
    top: 18px;
    margin-right: 24px;

    opacity: 1;

    z-index: 1;
  }

  .succes-content {
    opacity: 0;
  }

  .progress-bar {
    position: relative;

    width: 100%;
    height: 5px;
    background: #fefbff;
    margin-top: 16px;
    border-radius: 5px;

    span {
      position: absolute;

      top: 0;
      left: 0;

      background: red;
      width: 50%;
      height: 100%;

      border-radius: 5px;
    }
  }

  .title {
    color: white;
    margin-bottom: 8px;
  }

  .description {
    color: #626364;
    font-size: 14px;
    line-height: 17px;

  }
}

.navigation-elem {
  position: relative;
  height: 24px;
  align-items: center;

  color: #747474;

  display: flex;
  flex-direction: row;

  font-size: 18px;

  margin: 16px 0;
  padding: 8px 12px;

  transition: .3s ease all;
  box-sizing: content-box;

  &.router-link-active {
    color: white;
  }

  &:hover {
    background: #1e1e1e;
    border-radius: 3px;
  }

  .selected {
    position: absolute;
    height: 24px;
    right: -19px;
    width: 3px;
    background: white;
    border-radius: 3px;
  }

  svg {
    margin-right: 24px;
  }
}

.app-container {
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100vh;
}

.side-panel-container {
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  background: #171717;
  width: 284px;
  min-width: 284px;

  padding: 0 24px;

  box-shadow: #00000033 0px 2px 8px 0px;

  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}

.menu-container {
  display: flex;
  flex-direction: row;

  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  .elem {
    padding: 2px 8px;
    margin: 0 10px;

    cursor: pointer;

    border-radius: 3px;

    transition: 0.3s ease all;


    &.router-link-exact-active {
      color: white;
      background: #3E4249;
    }

    &:hover {
      background: #3E4249;
    }
  }
}

@keyframes blink {
  20%,
  24%,
  55% {
      color: #ccced0;
      text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
      text-shadow:
          0 0 6px rgba(202,228,225,0.98),
          0 0 30px rgba(202,228,225,0.42),
          0 0 12px rgba(30,132,242,0.58),
          0 0 22px rgba(30,132,242,0.84),
          0 0 38px rgba(30,132,242,0.88),
          0 0 60px rgba(30,132,242,1);
      color: #e6f2ff;
  }
}

.neon .title {
  font-family: "Sacramento";
  text-shadow: none;
  color: transparent;
  font-size: 28px;
  font-weight: bold;

  margin-top: 4px;
  margin-right: 48px;

  white-space: nowrap;
  animation: blink 35s infinite;

  text-shadow:
      0 0 6px rgba(202,228,225,0.98),
      0 0 30px rgba(202,228,225,0.42),
      0 0 12px rgba(30,132,242,0.58),
      0 0 22px rgba(30,132,242,0.84),
      0 0 38px rgba(30,132,242,0.88),
      0 0 60px rgba(30,132,242,1);
  color: #e6f2ff;
}

.header {
  position: relative;

  height: 64px;
  background: #202225;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);

  width: 100vw;
  z-index: 1000;

  .header-content {
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: row;

    max-width: 1500px;
    width: 100%;
    height: 100%;

    padding: 0 12px;

    margin: 0 auto;

    .info-container {
      display: flex;
      align-items: center;
      flex-direction: row;

      position: absolute;
      right: 12px;
    }

    .user-info {
      display: flex;
      flex-direction: column;
      text-align: right;
      margin-right: 12px;

      div {
        font-size: 14px;
        line-height: 18px;;
      }
    }
  }

  .profil-image {
    height: 48px;
    width: 48px;
    background-size: cover;
    background-position: center;
    border-radius: 100%;
  }
}

@media screen and (max-width: 780px) {
  .header-content {
    flex-direction: column !important;
    justify-content: center !important;
  }

  .menu-container {
    flex-direction: column !important;

    .elem {
      margin: 8px 0 !important;
    }
  }

  .neon {
    margin-bottom: 12px;
  }

  .info-container {
    position: initial !important;
  }

  .header {
    height: 252px;
  }
}


// from

.form-container {
    width: 100%;
    margin-top: 24px;

    text-align: left;

    cursor: pointer;

    label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;

      color: var(--header-primary);

      cursor: pointer;
    }

    input[type="text"], input[type="password"], input[type="email"], input[type="numeric"] {
      border: none;
      border-radius: 5px;

      margin-top: 14px;
      width: 100%;
      padding: 8px 16px;

      font-size: 16px;
      line-height: 28px;

      background: #202225;

      color: white;

      box-shadow:
        rgba(60, 66, 87, 0.16) 0px 0px 0px 1px;
      transition: .3s ease all;

      -webkit-appearance: none;

      &:focus {
        outline: none;
        box-shadow:
          rgba(58, 151, 212, 0.34) 0px 0px 0px 4px,
          rgba(60, 66, 87, 0.16) 0px 0px 0px 1px;
      }

    }
  }

  .form-inline {
    display: flex;
    flex-direction: row;

    .form-container {
      margin-right: 16px;
    }

    .form-container:last-child {
      margin-right: 0;
    }
  }

  .label-inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .form-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    text-align: left;
  }

.form-error {
  display: flex;
  align-items: flex-start;
  text-align: left;

  color: #f04747;

  margin-top: 6px;
  margin-left: 2px;

  svg {
    margin-top: 3px;

    height: 14px;
    width: 14px;
  }

  span {
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
  }
}

/* Customize the label (the container) */
.container-checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 4px;
  height: 18px;
  width: 18px;
  background-color: var(--header-secondary);

  border-radius: 1px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #43b581;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  opacity: 0.64;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid #6a3a307d;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

  @media screen and (max-width: 840px) {

    .form-inline {
      flex-direction: column;

      .form-container {
        margin-right: 0;
      }
    }

  }

</style>
