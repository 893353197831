import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/firestore'
import Vue from 'vue'

import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: "AIzaSyCbR9OYX_otCaqvwbLff38eJpuCSuHM3wU",
  authDomain: "chatters-alxios-com.firebaseapp.com",
  projectId: "chatters-alxios-com",
  storageBucket: "chatters-alxios-com.appspot.com",
  messagingSenderId: "165043795599",
  appId: "1:165043795599:web:3cee49c5da796e0c8b22fa",
  measurementId: "G-C9KBQFFEHP"
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// export utils/refs
export {
  db,
  auth,
}