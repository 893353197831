<template>
  <div class="home">

    <div class="page-container">
      

      <div class="form-container">
        <label for="text">Nom de chaine</label>
        <input type="text" v-model="streamName" placeholder="Alxios">
      </div>

      <label class="container-checkbox">
        <input type="checkbox" id="scales" name="scales" checked v-model="saveDefault">
        <span class="checkmark"></span>
        <span>Toujours ouvrir par défaut</span>
      </label>

      <button class="button" @click="open">Ouvrir {{ this.streamName }}</button>

      <!-- <div class="login-with-twitch" @click="login">
        <i class="fab fa-twitch"></i>Twitch
      </div> -->

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { auth, db } from '../firebase'

export default {
  name: 'Home',

  data () {
    return {
      saveDefault: true,
      streamName: ''
    }
  },

  async beforeCreate () {

    const hasAutoLogin = localStorage.getItem('autoLogin')

    if (hasAutoLogin) {
      this.$router.push({ name: 'Chatter', params: { username: hasAutoLogin } })
    }

    // const access_token = this.$route.hash.substring(14, 44) || localStorage.getItem('access_token')

    // if (access_token) {
    //   localStorage.setItem('access_token', access_token)
    //   await this.$store.dispatch('fetchUser')

    //   this.$router.push({ name: 'shop' })
    // }

  },

  methods: {

    open () {
      if (!this.streamName) return

      if (this.saveDefault) {
        console.log('auto login set : ', this.streamName)
        localStorage.setItem('autoLogin', this.streamName)
      }

      this.$router.push({ name: 'Chatter', params: { username: this.streamName } })
    },

    login () {
      window.location.replace(
        `https://id.twitch.tv/oauth2/authorize?response_type=token&client_id=vux0k8gnif6lfzqvp9ynt89vrfxdd5&redirect_uri=http://localhost:8080&scope=user:read:email`
      )
    }
  }
}

</script>

<style lang="scss" scoped>

.button {
    width: 200px;
    background: #7289da;

    color: white !important;

    height: 42px;
    min-height: 42px;

    font-weight: 600;
    line-height: 28px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    align-self: flex-end;

    margin-top: 24px;
    transition: all .3s;
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);
    }
  }

@media screen and (max-width: 900px) {
  .title {
    font-size: 42px !important;
  }

  .page-container {
    padding: 0 24px;
  }
}

.sub-title {
  color: rgb(189, 194, 196);
  font-size: 16px;

  font-weight: 400;

  margin-bottom: 36px;
}

.home {
  min-height: 100vh;
}

.page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  max-width: 800px;
  width: 100%;
  min-height: 100vh;

  margin: 0 auto;
}

.login-with-twitch {
  position: relative;

  background-color: #9146ff;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  margin-bottom: 8px!important;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  color: white;

  max-width: 400px;
  width: 100%;

  cursor: pointer;

  svg {
    position: absolute;
    left: 12px;
    height: 21px;
    width: 21px !important;
  }

  transition: .3s ease all;

  &:hover {
    background: lighten($color: #9146ff, $amount: 5);
  }
}


@keyframes blink {
  20%,
  24%,
  55% {
      color: #ccced0;
      text-shadow: none;
  }

  0%,
  19%,
  21%,
  23%,
  25%,
  54%,
  56%,
  100% {
      text-shadow:
          0 0 6px rgba(202,228,225,0.98),
          0 0 30px rgba(202,228,225,0.42),
          0 0 12px rgba(30,132,242,0.58),
          0 0 22px rgba(30,132,242,0.84),
          0 0 38px rgba(30,132,242,0.88),
          0 0 60px rgba(30,132,242,1);
      color: #e6f2ff;
  }
}

.header .title {
  font-family: "Sacramento";
  text-shadow: none;
  color: transparent;
  font-size: 64px;
  font-weight: bold;

  white-space: nowrap;

  margin-bottom: 148px;

  animation: blink 35s infinite;

  text-shadow:
      0 0 6px rgba(202,228,225,0.98),
      0 0 30px rgba(202,228,225,0.42),
      0 0 12px rgba(30,132,242,0.58),
      0 0 22px rgba(30,132,242,0.84),
      0 0 38px rgba(30,132,242,0.88),
      0 0 60px rgba(30,132,242,1);
  color: #e6f2ff;
}

.form-container {
    width: 100%;
    margin-top: 24px;

    text-align: left;

    cursor: pointer;

    label {
      font-size: 18px;
      line-height: 20px;
      font-weight: 500;

      color: var(--header-primary);

      cursor: pointer;
    }

    input[type="text"], input[type="password"], input[type="email"], input[type="numeric"] {
      border: none;
      border-radius: 5px;

      margin-top: 14px;
      width: 100%;
      padding: 8px 16px;

      font-size: 16px;
      line-height: 28px;

      background: #202225;

      color: white;

      box-shadow:
        rgba(60, 66, 87, 0.16) 0px 0px 0px 1px;
      transition: .3s ease all;

      -webkit-appearance: none;

      &:focus {
        outline: none;
        box-shadow:
          rgba(58, 151, 212, 0.34) 0px 0px 0px 4px,
          rgba(60, 66, 87, 0.16) 0px 0px 0px 1px;
      }

    }
  }

  .form-inline {
    display: flex;
    flex-direction: row;

    .form-container {
      margin-right: 16px;
    }

    .form-container:last-child {
      margin-right: 0;
    }
  }

  .label-inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .form-info {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    text-align: left;
  }

.form-error {
  display: flex;
  align-items: flex-start;
  text-align: left;

  color: #f04747;

  margin-top: 6px;
  margin-left: 2px;

  svg {
    margin-top: 3px;

    height: 14px;
    width: 14px;
  }

  span {
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
  }
}

/* Customize the label (the container) */
.container-checkbox {

  align-self: flex-end;

  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin-top: 6px;


  span {
    font-size: 18px;
  }
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 4px;
  height: 18px;
  width: 18px;
  background-color: #ccc;
  border-radius: 1px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
  background-color: #43b581;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  opacity: 0.64;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 3px;
  height: 6px;
  border: solid #6a3a307d;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

  @media screen and (max-width: 840px) {

    .form-inline {
      flex-direction: column;

      .form-container {
        margin-right: 0;
      }
    }

  }


</style>

