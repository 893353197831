import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
  
  {
    meta: { domains: 'chatters.alxios.com'},
    path: '/:username',
    name: 'Chatter',
    component: () => import('../views/bot/chatter.vue')
  },

  // local testing
  {
    meta: { domains: 'localhost:8080'},
    path: '/chatters/:username',
    name: 'local-Chatter',
    component: () => import('../views/bot/chatter.vue')
  },

  {
    path: '/',
    name: 'Home',
    component: Home
  },

  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   component: Dashboard
  // },
  
  // {
  //   path: '/shop',
  //   name: 'shop',
  //   component: () => import('../views/Shop.vue')
  // },
  // {
  //   path: '/achievement',
  //   name: 'achievement',
  //   component: () => import('../views/achievement.vue')
  // },
  // {
  //   path: '/requests',
  //   name: 'request',
  //   component: () => import('../views/Request.vue')
  // },
  // {
  //   path: '/classement',
  //   name: 'classement',
  //   component: () => import('../views/Classement.vue')
  // },
  // {
  //   path: '/hype',
  //   name: 'hype',
  //   component: () => import('../views/hype.vue')
  // },
  // {
  //   path: '/all',
  //   name: 'request',
  //   component: () => import('../views/RequestAll.vue')
  // },
  // {
  //   path: '*',
  //   redirect: { name: 'shop' }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Home') next()
  
  //Sub-domain routing
  let host = window.location.host.toUpperCase()

  console.log(host)

  for (let i = 0; i < to.matched.length; i++) {
    if (to.matched[i].meta.domains) {
      console.log(to.matched[i].meta.domains.toUpperCase())
      if (host.includes(to.matched[i].meta.domains.toUpperCase())){
        return next()
      }
    } else {
      next({ name: 'Home' })
    }
  }
  next({ name: 'Home' })
})

export default router
