<template>
  <div class="home">
    <div class="page-content">

      <div class="info-container">
        <div class="info-content">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>

        <div class="info-content fake">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>

        <i class="fad fa-info"></i>
      </div>

      <div class="header">

        <div class="display_name">{{ user.display_name }}</div>

        <div class="is_sub" v-if="userData.isSubscriber">
          <i class="fas fa-star"></i>
          Abonné(e) Tier 1
        </div>

      </div>

      <div class="sub-header">

        <i class="far fa-chevron-double-right"></i>

        Follow depuis le 24 Obtobre

      </div>

      <div class="dashboard-container">

        <div class="line-container">

          <div class="card-container double">
            <div class="card-header">
              Achievement
            </div>
          </div>

          <div class="card-container">
            <div class="card-header">
              Transactions
            </div>

          </div>

        </div>


        <div class="line-container">

          <div class="card-container">
            <div class="card-header">
              Achievement
            </div>
          </div>

          <div class="card-container">
            <div class="card-header">
              En attentes
            </div>

          </div>

          <div class="shop-card card-container">
            <div class="card-header">
              La boutique
            </div>

          
            <i class="fad fa-shopping-cart fa-flip-horizontal background-icon" flip></i>

          </div>

        </div>


      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { auth, db } from '../firebase'
import { mapState } from 'vuex'
import moment from 'moment'

moment.locale('fr');  

export default {
  name: 'Dashboard',

  firestore() {
    return {
      users: db.collection('users')
    }
  },

  data () {
    return {
      rewards: [],
      games: [],
      users: [],

      moment
    }
  },

  computed: {
    ...mapState(['user']),
    userData() {
      return this.users.find(x => x.userId === this.user.id) || {}
    }
  }

}

</script>

<style lang="scss" scoped>

.line-container {
  display: flex;

  .card-container {
    position: relative;
    overflow: hidden;

    width: 100%;
    height: 300px;
    background: #171717;
    margin: 10px;
    border-radius: 10px;

    padding: 18px 24px;

    cursor: pointer;

    transition: 0.5s ease all;

    box-shadow: transparent 0px 0px 0px 3px;

    .background-icon {
      position: absolute;
      top: 20%;
      right: -12%;
      height: 100%;
      width: 100%;
    }


    &:hover {
      box-shadow: #9999995c 0px 0px 0px 3px;
    }

    .card-header {
      color: white;
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.shop-card {
  background-color: #8BC6EC !important;
  background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%) !important;

  svg {
    color: #2c6094;
    position: absolute;
    top: 14% !important;
    right: -20% !important;
    height: 100%;
    width: 100%;
  }
}

.sub-header {
  margin: 18px 0;

  color: #999999;

  font-weight: 400;

  svg {
    margin-right: 8px;
  }
}

.header {
  display: flex;
  flex-direction: row;

  .display_name {
    font-size: 32px;
    color: white;
    font-weight: 600;
    margin-right: 20px;
  }

  .is_sub {
    background: #171717;
    border-radius: 10px;
    color: #e6ff18;
    padding: 10px 12px;
    padding-right: 16px;
    font-size: 15px; 

    margin-bottom: -6px;

    svg {
      margin-right: 5px;
    }
  }

  
}

.info-container {
  position: relative;

  padding: 18px 24px;
  border-radius: 10px;
  color: white;
  margin-bottom: 42px;
  font-size: 15px;
  line-height: 18px;

  overflow: hidden;

  background-color: #FBAB7E;
  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);

  box-shadow: #00000033 0px 2px 8px 0px;

  &::after {
    color: #fbad7d;
    position: absolute;
    top: -2px;
    font-weight: 700;
    letter-spacing: 2px;
    left: 24px;
    text-transform: uppercase;
    background: #1e1e1e;
    border: 2px solid #fab07a;
    padding: 4px 8px;
    border-radius: 6px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    content: 'Information';
  }

  svg {
    position: absolute;
    top: 0;
    left: 49.5%;
    height: 100%;
    width: 100%;
    color: #ffa93ff2;
    z-index: 0;
  }

  .info-content {
    margin-top: 22px;

    z-index: 1;
    opacity: 0;

    line-height: 20px;

    text-shadow: 1px 1px 6px #00000059;

    &.fake {
      opacity: 1;
      position: absolute;
      top: 18px;
      margin-right: 24px;
    }
  }
}

.home {
  max-height: calc(100vh);
  height: 100%;
  overflow: auto;
}

.page-content {
  max-width: 1500px;
  margin: 0 auto;
  padding-top: 32px;
}

@media screen and (max-width: 1500px) {

}

@media screen and (max-width: 1225px) {

}

@media screen and (max-width: 900px) {

}


@media screen and (max-width: 610px) {

}

</style>

